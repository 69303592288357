import React from "react";
//MUI
import theme from "../../../../Theme";
import { Box, Typography, Rating, Avatar, CardHeader } from "@mui/material";
//icons
import StarIcon from "@mui/icons-material/Star";
import { IoMdLaptop } from "react-icons/io";
import useDeviceSize from "../../../../Service/device-size";

const LayoutWhyChooseUs = ({
  rating,
  title,
  content,
  image,
  name,
  location,
  minH,
  ICON,
  mainTitle,
}) => {
  const { isMobile } = useDeviceSize();
  return (
    <React.Fragment>
      <Box
        sx={{
          filter: "drop-shadow(0px 10px 5px rgba(0, 0, 0, .1))",
          margin: "0px 0 30px 0",
        }}
      >
        <Box
          sx={{
            clipPath:
              "polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 5px), 0 100%)",
            backgroundColor: `rgba(${theme.palette.text.white},1)`,
            height: "calc(100% - 30px)",
            padding:mainTitle === "Delivery In" ?  "30px 40px 0px 40px" : "30px 40px",
            borderRadius: "6px",
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            minHeight: minH || "150px",
          }}
        >
          <Box
            sx={{
              // background: "rgba(254, 206,120,1)",
              // padding: "30px 32px",
              width: "fit-content",
              borderRadius: "51px",
            }}
          >
            {ICON}
          </Box>
          <Typography
            sx={{
              textWrap: "balance",
              mt: 3,
              fontSize: "1.1em",
              textWrap: "unset",
              color: theme.palette.text.orange,
              marginTop: mainTitle === "Delivery In" ? "-3px" : 0,
            }}
            variant="h6"
          >
            {mainTitle}
          </Typography>
          <Typography
            sx={{ textWrap: "balance", mt: -1, textWrap: "unset" }}
            variant="h6"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: 1.5,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              //"-webkit-line-clamp": "4",
              //"-webkit-box-orient": "vertical",
            }}
          >
            {/* {content} */}
          </Typography>

          <CardHeader
            sx={{ padding: "10px 0 0 0" }}
            // avatar={<Avatar alt="Remy Sharp" src={image} />}
            title={
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {/* {name} */}
              </Typography>
            }
            subheader={
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, opacity: ".5" }}
              >
                {location}
              </Typography>
            }
            disableTypography
          />
        </Box>
      </Box>

      {/* <Box
        sx={{
          filter: "drop-shadow(0px 10px 5px rgba(0, 0, 0, .1))",
          margin: "0px 0 30px 0",
          height: "calc(100% - 75px)",
        }}
      >
        <Box
          sx={{
            clipPath:
              "polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 5px), 0 100%)",
            backgroundColor: `rgba(${theme.palette.text.white},1)`,
            height: "calc(100% - 30px)",
            padding: "30px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Rating
            name="half-rating-read"
            defaultValue={rating}
            precision={0.5}
            readOnly
            size="small"
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <Typography variant="h6">{title}</Typography>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: 1.5,
              overflow: "hidden",
              display: "-webkit-box",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical",
            }}
          >
            {content}
          </Typography>

          <CardHeader
            sx={{ padding: "10px 0 0 0" }}
            avatar={<Avatar alt="Remy Sharp" src={image} />}
            title={
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {name}
              </Typography>
            }
            subheader={
              <Typography
                sx={{ fontSize: "12px", fontWeight: 600, opacity: ".5" }}
              >
                {location}
              </Typography>
            }
            disableTypography
          />
        </Box>
      </Box> */}
    </React.Fragment>
  );
};

export default LayoutWhyChooseUs;
