import React, { useEffect, useState } from "react";
//MUI
import theme from "../../../Theme";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  List,
  useMediaQuery,
} from "@mui/material";
//Custom Components

import * as Yup from "yup";
/*Images and Icons */
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";
import { POST_RESEND_OTP, POST_VERIFY_OTP } from "../../../Service/ApiRoutes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OTP = ({ setShowOtp, phone, TenNum }) => {
  const nav = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  // const handleFormSubmit = (values) => {
  //   console.log(values);
  // };
  const Validation = Yup.object({
    otp: Yup.string().required("Please enter OTP "),
  });
  const [counter, setCounter] = useState(0); // 30 seconds countdown
  const [canResend, setCanResend] = useState(false);

  // Start the countdown when the component mounts
  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [counter]);

  const handleResendOTP = () => {
    const Payload = {
      mobile_num: phone,
      retryType: "text",
    };
    setCounter(30); // Reset the counter
    setCanResend(false);
    axios.post(POST_RESEND_OTP(), Payload).then((data) => {
      toast.success("OTP Resend Success", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowOtp(true);
    });
  };

  const handleFormSubmit = (values) => {
    if (values) {
      axios.post(POST_VERIFY_OTP(), values).then(({ data }) => {
        toast.success("Verify Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(phone);
        console.log(data);
        if (phone === "918766265982") {
          nav("/admin/users");
          localStorage.setItem("TOKEN", data?.data);
          localStorage.setItem("ROLE", data?.role);
        } else {
          nav("/");
          localStorage.setItem("TOKEN", data?.data);
          localStorage.setItem("ROLE", data?.role);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "480px" }}>
        <Typography variant="h5">Verify OTP</Typography>
        <Typography variant="body1" mt={2}>
          Please enter the OTP (One Time Password) sent to {TenNum}
        </Typography>
        <Formik
          initialValues={{
            mobile_num: phone,
            otp: "",
          }}
          validationSchema={Validation}
          // enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, handleSubmit }) => (
            <Form>
              <Field name="otp">
                {({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Enter OTP"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    margin="normal"
                  />
                )}
              </Field>
              <ErrorMessage name="otp" component="div" className="errors" />

              {/* <Link to={"/user/enquiries"}> */}
              <Box className="FlexCenter gap-3 mt-2">
                <Button
                  // type="button"
                  variant="outlined"
                  fullWidth
                  size="large"
                  sx={{
                    borderRadius: "50px",
                  }}
                  onClick={() => setShowOtp(false)}
                >
                  Back
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    color: `rgba(${theme.palette.text.white},1)`,
                    borderRadius: "50px",
                  }}
                >
                  Verify
                </Button>
              </Box>
              <Button
                variant="outlined"
                fullWidth
                size="medium"
                onClick={handleResendOTP}
                disabled={!canResend}
                sx={{
                  mt: 2,
                  border: 0,
                  background: "transparent",
                  fontWeight: 700,
                  transition: "all 200ms ease",
                  "&:hover": {
                    border: "none",
                    background: "transparent",
                    transform: "scale(.98)",
                  },
                }}
              >
                {canResend ? "Resend OTP" : `Resend OTP in ${counter}s`}
              </Button>
              {/* </Link> */}
              {/* Resend OTP Button */}
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
};

export default OTP;
