import React from "react";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
} from "@mui/material";
import theme from "../../../Theme";
export const AddDetailsModal = ({ Toggler }) => {
  const handleFormSubmit = () => {};
  return (
    <ModaalDark anchorEl={true}>
      <div className="Modal1200">
        <Typography
          variant="body2"
          align="left"
          sx={{
            //   fontSize: "1.2em",
            fontWeight: 700,
          }}
        >
          Add Details
        </Typography>
        <span
          onClick={() => Toggler()}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent">
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.orange1,
              fontWeight: 500,
            }}
          >
            Personal Info
          </Typography>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              alternate_num: "",
              gender: "",
              marital_status: "",
              current_address: "",
              permanent_address: "",
              bank_name: "",
              account_num: "",
              ifsc_code: "",
              holder_name: "",
              branch: "",
              email: "",
              work_profile: "",
            }}
            // validationSchema={VALIDATION}
            // enableReinitialize
            onSubmit={handleFormSubmit}
          >
            {({ setFieldValue, handleSubmit }) => (
              <Form>
                <div className="Grid8 gap-3 m-0 mt-3 p-2">
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        First Name
                      </label>
                      <Field name="first_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Last Name
                      </label>
                      <Field name="last_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Phone Number
                      </label>
                      <Field name="employee_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Number"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Alternate Number
                      </label>
                      <Field name="employee_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Number"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Mail
                      </label>
                      <Field name="employee_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>

                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Work Profile
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="loan_type"
                        className="Custom_Select"
                      >
                        {[
                          "Architect",
                          "Interior Design",
                          "Civil Engineer",
                          "Carpenter",
                          "Marketing Agent",
                          "Client",
                          "Others",
                        ]?.map((Elem) => {
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("department", Elem);
                                // setDepartmentValue(Elem);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="loan_type"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>

                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Gender
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="loan_type"
                        className="Custom_Select"
                      >
                        {["Male", "Female", "Other"]?.map((Elem) => {
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("jobtitle", Elem);
                                // setLeaveTypeValue(Elem?.name);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="jobtitle"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Marital Status
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="loan_type"
                        className="Custom_Select"
                      >
                        {["Married", "Single"]?.map((Elem) => {
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("department", Elem);
                                // setDepartmentValue(Elem);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="loan_type"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>
                </div>
                <div className="Grid2 gap-3 mt-2">
                  <div className="">
                    {" "}
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Current Address
                    </label>
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600"></label>
                      <Field name="employee_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            multiline
                            rows={4}
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    {" "}
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Parmanent Address
                    </label>
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600"></label>
                      <Field name="employee_id">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            multiline
                            rows={4}
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="FlexEnd">
            <button
              className="btnYellow mt-3 "
              style={{
                padding: ".8em 2em",
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </ModaalDark>
  );
};
