import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { ConsultationTable } from "./ConsultationTable";
import axios from "axios";
import { GET_CONSULT } from "../../../Service/ApiRoutes";

const Consultation = () => {
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [type, settype] = useState("");
  const [ConsultData, setConsultData] = useState([]);
  const HandleGetUSerAgents = () => {
    axios
      .get(GET_CONSULT(), {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setConsultData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [type]);
  console.log(ConsultData);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Consulatation
        </Typography>

        <ConsultationTable Data={ConsultData} />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Consultation;
