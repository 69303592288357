import React from "react";

//Images and Icons
import bannerImage from "../../Assets/images/crockeryunits/Cu01.jpg";

const BannerDetails = {
  type: "fullimage",
  image: bannerImage,
  title: "Modular kitchen designs",
  desc: "For those who love to entertain, a stylish crockery unit is essential. At Modulin, our modular crockery units are designed to showcase your best tableware while also keeping everything organized. Whether you’re displaying heirlooms or your everyday sets, our units offer both aesthetic appeal and practicality, blending effortlessly into your dining space.",
  bannertext: false,
};

////////////////////////////////////////////////
// List of image filenames to hide
const imagesToHide = ["Ushape06.jpg"];

// Function to import all images from a given context and exclude specified images
const importAllImages = (requireContext) =>
  requireContext
    .keys()
    .filter((key) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      return !imagesToHide.includes(imageName);
    })
    .map((key, index) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      const pathParts = key.split("/");
      const folderName =
        pathParts.length >= 2
          ? pathParts[pathParts.length - 2]
          : "Unknown Folder"; // Get the folder name or use "Unknown Folder"
      return {
        id: index + 1, // Generate unique ID using the index
        title: imageName.replace(/\.[^/.]+$/, ""), // Remove file extension for title
        imageUrl: requireContext(key),
        category: folderName,
      };
    });

// Import all images from the specified context
const Gallery = importAllImages(
  require.context(
    "../../Assets/images/crockeryunits",
    true, // Include subdirectories
    /\.(png|jpe?g|webp)$/
  )
);
////////////////////////////////////////////////

export { Gallery, BannerDetails };
