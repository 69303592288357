import { Box, Typography, IconButton, SvgIcon, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { user, menu, wardrobe } from "../../../Assets/icons/icons";

// import poojagharMorning from "../../../Assets/slide/poojaghar_morning.jpg";
// import poojagharNature from "../../../Assets/slide/poojaghar_nature.jpg";
// import poojagharSky from "../../../Assets/slide/sky/poojaghar_sky.jpg";
import icon1 from "../../../Assets/ic1.png";
import icon2 from "../../../Assets/ic2.png";
import icon3 from "../../../Assets/ic3.png";

import AtiUttam from "../../../Assets/slide/rightLogo/Ath.png";
import Uttam from "../../../Assets/slide/rightLogo/uth.png";
import Kanist from "../../../Assets/slide/rightLogo/kan.png";

import kitchen1 from "../../../Assets/slide/kitchen/kitchen1.jpg";
import kitchen1Theme from "../../../Assets/slide/kitchen/kitchen1Theme.jpg";
import kitchen2 from "../../../Assets/slide/kitchen/kitchen2.jpg";
import kitchen2Theme from "../../../Assets/slide/kitchen/kitchen2Theme.jpg";
import kitchen3 from "../../../Assets/slide/kitchen/kitchen3.jpg";
import kitchen3Theme from "../../../Assets/slide/kitchen/kitchen3Theme.jpg";

import ward1 from "../../../Assets/slide/wardrope/ward1.jpg";
import ward1Theme from "../../../Assets/slide/wardrope/ward1Theme.jpg";
import ward2 from "../../../Assets/slide/wardrope/ward2.jpg";
import ward2Theme from "../../../Assets/slide/wardrope/ward2Theme.jpg";
import ward3 from "../../../Assets/slide/wardrope/ward3.jpg";
import ward3Theme from "../../../Assets/slide/wardrope/ward3Theme.jpg";

import wardrobeGold from "../../../Assets/slide/wardrobe_morning.jpg";
import wardrobeGreen from "../../../Assets/slide/wardrobe_nature.jpg";
import wardrobeSky from "../../../Assets/slide/wardrobe_sky.jpg";

import morning from "../../../Assets/slide/morning.jpg";
import nature from "../../../Assets/slide/nature.jpg";
import sky from "../../../Assets/slide/sky.jpg";
import theme from "../../../Theme";
import ModalSection from "../ModalSection";
import ConsultationForm from "../ConsultationForm/ConsultationForm";
import useDeviceSize from "../../../Service/device-size";
import { useNavigate } from "react-router-dom";

const themeColor = {
  morning: {
    // color: "227,179,77",
    color1: "118, 83, 63",
    color: "220, 155, 117",
    color2: "220, 155, 117",
    contrastText: "0,0,0",
  },
  nature: {
    color: "23,133,116",
    contrastText: "255,255,255",
  },
  sky: {
    color: "102,170,219",
    contrastText: "0,0,0",
  },
  red: {
    color: "255, 7, 7, 1",
    contrastText: "0,0,0",
  },
};

const bannerItems = [
  // {
  //   id: 1,
  //   sliderName: "Pooja Ghar",
  //   sliderIcon: wardrobe,
  //   sliderTheme: {
  //     morning: {
  //       themeImage: morning,
  //       bannerImage: poojagharMorning,
  //       bannerText: "Pooja Ghar Morning Theme",
  //     },
  //     nature: {
  //       themeImage: nature,
  //       bannerImage: poojagharNature,
  //       bannerText: "Pooja Ghar Nature Theme",
  //     },
  //     sky: {
  //       themeImage: sky,
  //       bannerImage: poojagharSky,
  //       bannerText: "Pooja Ghar Sky Theme",
  //     },
  //   },
  // },
  // {
  //   id: 2,
  //   sliderName: "Tv Unit",
  //   sliderIcon: menu,
  //   sliderTheme: {
  //     morning: {
  //       themeImage: morning,
  //       bannerImage: tvUnitMorning,
  //       bannerText: "Tv Unit Morning Theme",
  //     },
  //     nature: {
  //       themeImage: nature,
  //       bannerImage: tvUnitNature,
  //       bannerText: "Tv Unit Nature Theme",
  //     },
  //     sky: {
  //       themeImage: sky,
  //       bannerImage: tvUnitSky,
  //       bannerText: "Tv Unit Sky Theme",
  //     },
  //   },
  // },
  {
    id: 1,
    sliderName: "Kitchen",

    sliderIcon: menu,
    sliderTheme: {
      morning: {
        categoryImg: AtiUttam,
        themeImage: kitchen1Theme,
        bannerImage: kitchen1,
        bannerText: "The ultimate modular Destination.",
      },
      nature: {
        categoryImg: Uttam,
        themeImage: kitchen2Theme,
        bannerImage: kitchen2,
        bannerText: "The ultimate modular Destination.",
      },
      sky: {
        categoryImg: Kanist,
        themeImage: kitchen3Theme,
        bannerImage: kitchen3,
        bannerText: "The ultimate modular Destination.",
      },
    },
  },
  {
    id: 2,
    sliderName: "Wardrobe",
    sliderIcon: menu,
    sliderTheme: {
      morning: {
        categoryImg: AtiUttam,
        themeImage: ward1Theme,
        bannerImage: ward1,
        bannerText: "The ultimate modular Destination.",
      },
      nature: {
        categoryImg: Uttam,
        themeImage: ward2Theme,
        bannerImage: ward2,
        bannerText: "The ultimate modular Destination.",
      },
      sky: {
        categoryImg: Kanist,
        themeImage: ward3Theme,
        bannerImage: ward3,
        bannerText: "The ultimate modular Destination.",
      },
    },
  },
];

const HomeBannerNew = () => {
  const { isXL } = useDeviceSize();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTheme, setCurrentTheme] = useState("morning");
  const [selectedSection, setSelectedSection] = useState(bannerItems[0]);

  console.log(currentTheme);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        return (prevIndex + 1) % bannerItems.length;
      });
    }, 300000); // Change slide every 5 minutes

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    setSelectedSection(bannerItems[currentIndex]);
  }, [currentIndex]);

  const currentItem = bannerItems[currentIndex];
  const themes = Object.keys(selectedSection.sliderTheme);

  // Reorder themes array to move the selected theme to the first position
  const reorderedThemes = [
    currentTheme,
    ...themes.filter((theme) => theme !== currentTheme),
  ];

  console.log(reorderedThemes);
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End
  const nav = useNavigate();
  return (
    <React.Fragment>
      <Box
        className="HomeBanner"
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          boxSizing: "border-box",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: "200px",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <img onClick={() => {
              nav("/kitchens")
            }}
            src={icon1}
            alt=""
            style={{
              width: "50px",
            }}
          />
          <img
            onClick={() => {
              nav("/offices")
            }}
            src={icon2}
            alt=""
            style={{
              width: "50px",
            }}
          />
          <img
            onClick={() => {
              nav("/retail")
            }}
            src={icon3}
            alt=""
            style={{
              width: "50px",
            }}
          />
        </Box>
        <Box sx={{ flex: 1, position: "relative" }}>
          <Box
            sx={{
              flex: 1,
              height: "100%",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <motion.div
              key={currentItem.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                backgroundImage: `url(${selectedSection.sliderTheme[currentTheme].bannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                className="bannerHeading"
                sx={{
                  // maxWidth: "1300px",
                  maxWidth: "100%",
                  position: "absolute",
                  bottom: "60px",
                  left: 0,
                  p: 2,
                  // background: `linear-gradient(100deg, rgba(${themeColor[currentTheme].color}, 0.5) 0%,rgba(${themeColor[currentTheme].color}, .3) 25%, rgba(${themeColor[currentTheme].color}, 0) 90%)`,
                  background: themeColor[currentTheme].color1
                    ? `linear-gradient(100deg, rgba(${themeColor[currentTheme].color1}, 1) ,rgba(${themeColor[currentTheme].color}, .5), rgba(${themeColor[currentTheme].color2}, 0) )`
                    : `linear-gradient(100deg, rgba(${themeColor[currentTheme].color}, 0.5) 0%,rgba(${themeColor[currentTheme].color}, .3) 25%, rgba(${themeColor[currentTheme].color}, 0) 90%)`,
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                {console.log(themeColor[currentTheme].color)}
                <Typography
                  variant="h6"
                  color={"white"}
                  fontSize={"28px"}
                  textTransform={"capitalize"}
                >
                  {selectedSection.sliderTheme[currentTheme].bannerText}
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <Button
                    variant="contained"
                    sx={{
                      // backgroundColor: `rgba(${themeColor[currentTheme].color},.8)`,
                      // color: `rgba(${themeColor[currentTheme].contrastText},1)`,
                      backgroundColor: `rgba(${themeColor.red.color},.8)`,
                      color: `rgba(255,255,255,1)`,
                      px: 1.5,
                      py: 1,
                      boxSizing: "border-box",
                      textTransform: "inherit",
                      whiteSpace: "nowrap",
                      fontWeight: 700,

                      letterSpacing: 1,
                      "&:hover": {
                        backgroundColor: `rgba(${themeColor[currentTheme].color},1)`,
                      },
                    }}
                    onClick={handleClickOpen}
                  >
                    Get Free Consultation
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginLeft: "100px",
                  }}
                  className="BannerCategoryName"
                >
                  {bannerItems.map((item) => (
                    <Button
                      key={item.id}
                      onClick={() => setSelectedSection(item)}
                      sx={{
                        textTransform: "inherit",
                        color: "white",
                        fontSize: "1.5em",
                        marginLeft: "0em",
                      }}
                      // color="white"
                    >
                      {item.sliderName}
                    </Button>
                  ))}
                </Box>
              </Box>
            </motion.div>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "30px",
              right: "30px",
              display: "flex",
              alignItems: "center",

              padding: "5px",
              borderRadius: "5px",
              gap: 1,
              zIndex: 5,
            }}
          >
            {bannerItems.map((item) => (
              <Button
                key={item.id}
                onClick={() => setSelectedSection(item)}
                size="small"
                sx={{ textTransform: "inherit" }}
                color="inherit"
              >
                {/* {item.sliderName} */}
              </Button>
            ))}
          </Box>
          <Box
            className="BannerCategory"
            sx={{
              position: "absolute",
              bottom: "80px",
              right: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              padding: "5px",
              borderRadius: "5px",
              gap: 1,
              zIndex: 5,
            }}
          >
            {reorderedThemes.map((theme) => (
              <img
                onClick={() => setCurrentTheme(theme)}
                src={selectedSection.sliderTheme[theme].categoryImg}
                alt=""
                style={{
                  width:
                    theme === "morning"
                      ? isXL
                        ? "240px"
                        : "180px"
                      : theme === "nature"
                      ? isXL
                        ? "180px"
                        : "120px"
                      : isXL
                      ? "170px"
                      : "120px",
                  filter:
                    theme === currentTheme
                      ? "brightness(1.3) contrast(1.2)" // Increase brightness/contrast for active image
                      : "brightness(.8) contrast(.8)", // Default for inactive images
                  cursor: "pointer",
                  transition: "filter 0.3s ease", // Smooth transition effect
                }}
              />
            ))}
          </Box>
        </Box>
        {/* Category Name */}
        {/* <Box sx={{ display: "flex", gap: 0 }}>
          {reorderedThemes.map((theme) => (
            <motion.div
              key={theme}
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 80,
                height: "100%",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Box
                onClick={() => setCurrentTheme(theme)}
                sx={{
                  backgroundImage: `url(${selectedSection.sliderTheme[theme].themeImage})`,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  width: 80,
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    opacity: 0,
                    transition: "opacity 0.3s ease",
                    zIndex: 1,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    },
                    "&:active": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                    zIndex: 2,
                  }}
                />
              </Box>
            </motion.div>
          ))}
        </Box> */}
      </Box>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm onClose={handleClose} />
      </ModalSection>
    </React.Fragment>
  );
};

export default HomeBannerNew;
