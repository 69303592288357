import React from "react";
import Slide01 from "../../Assets/images/office/office/new/de3.jpg";
import Slide02 from "../../Assets/images/office/office/new/de2.png";
import Slide03 from "../../Assets/images/office/retail/Re15.jpg";
import Slide04 from "../../Assets/images/office/retail/Re13.jpg";
import Of1 from "../../Assets/images/office/office/new/de3.jpg";
import Of3 from "../../Assets/images/office/office/new/de4.png";
import Of4 from "../../Assets/images/office/office/new/fiv.jpg";
import Of7 from "../../Assets/images/office/office/new/five.jpg";
import Of8 from "../../Assets/images/office/office/new/foru.jpg";
import Of9 from "../../Assets/images/office/office/new/four.jpg";
import Of10 from "../../Assets/images/office/office/new/nine.jpg";
import Of11 from "../../Assets/images/office/office/new/one.jpg";
import Of12 from "../../Assets/images/office/office/new/seven.jpg";
import Of13 from "../../Assets/images/office/office/new/six.jpg";
import Of14 from "../../Assets/images/office/office/new/steptodown.com278306.jpg";
import Of15 from "../../Assets/images/office/office/new/steptodown.com321838.jpg";
import Of16 from "../../Assets/images/office/office/new/steptodown.com330076.jpg";
import Of17 from "../../Assets/images/office/office/new/steptodown.com369349.jpg";
import Of18 from "../../Assets/images/office/office/new/steptodown.com396732.jpg";
import Of19 from "../../Assets/images/office/office/new/steptodown.com480093.jpg";
import Of20 from "../../Assets/images/office/office/new/steptodown.com697336.jpg";
import Of21 from "../../Assets/images/office/office/new/steptodown.com733217.jpg";
import Of22 from "../../Assets/images/office/office/new/steptodown.com872525.jpg";
import Of23 from "../../Assets/images/office/office/new/steptodown.com951807.jpg";
import Of24 from "../../Assets/images/office/office/new/ten.jpg";
import Of25 from "../../Assets/images/office/office/new/tewel.jpg";
import Of26 from "../../Assets/images/office/office/new/thir.jpg";
import Of27 from "../../Assets/images/office/office/new/three.jpg";
import Of28 from "../../Assets/images/office/office/new/two.jpg";
// import Of29 from "../../Assets/images/office/office/new/Of29.jpg";
// import Of30 from "../../Assets/images/office/office/new/Of30.jpg";
// import Of31 from "../../Assets/images/office/office/new/Of31.jpg";
// import Of32 from "../../Assets/images/office/office/new/Of32.jpg";

// Retail Images
import Re1 from "../../Assets/images/office/retail/Re1.jpg";
import Re2 from "../../Assets/images/office/retail/Re2.jpg";
import Re3 from "../../Assets/images/office/retail/Re3.jpg";
import Re4 from "../../Assets/images/office/retail/Re4.jpg";
import Re5 from "../../Assets/images/office/retail/Re5.jpg";
import Re6 from "../../Assets/images/office/retail/Re6.jpg";
import Re7 from "../../Assets/images/office/retail/Re7.jpg";
// import Re8 from "../../Assets/images/office/retail/Re8.jpg";
import Re9 from "../../Assets/images/office/retail/Re9.jpg";
import Re11 from "../../Assets/images/office/retail/Re11.jpg";
import Re12 from "../../Assets/images/office/retail/Re12.jpg";
import Re13 from "../../Assets/images/office/retail/Re13.jpg";
import Re14 from "../../Assets/images/office/retail/Re14.jpg";
import Re15 from "../../Assets/images/office/retail/Re15.jpg";
import Re16 from "../../Assets/images/office/retail/Re16.jpg";
import Re17 from "../../Assets/images/office/retail/Re17.jpg";
import Re18 from "../../Assets/images/office/retail/Re18.jpg";
import Re19 from "../../Assets/images/office/retail/Re19.jpg";
import Re20 from "../../Assets/images/office/retail/Re20.jpg";
import Re21 from "../../Assets/images/office/retail/Re21.jpg";

const bannerDetails = [
  {
    id: 1,
    imageUrl: Slide01,
    title: "At Hassle-Free Commercial Interiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "236,216,206,1",
  },
  {
    id: 2,
    imageUrl: Slide02,
    title: "At Hassle-Free Commercial Interiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "205,251,117,1",
  },
];

const bannerDetailsRetail = [
  {
    id: 1,
    imageUrl: Slide03,
    title: "At Hassle-Free Commercial Interiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "236,216,206,1",
  },
  {
    id: 2,
    imageUrl: Slide04,
    title: "At Hassle-Free Commercial Interiors",
    desc: "we make commercial interior design a seamless and worry-free process.",
    bgcol: "205,251,117,1",
  },
];

const sectorDetails = [
  {
    id: 1,
    imageUrl: Slide01,
    title: "Offices",
    link: "#",
  },
  {
    id: 2,
    imageUrl: Slide01,
    title: "Retails",
    link: "#",
  },
];

const sectorDetailsSettings = {
  items: 2,
  margin: 60,
  autoplay: false,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
  },
};

const OfficesDetails = [
  {
    id: 1,
    title: "Offices 01",
    imageUrl: Of1,
    link: "/commercial/offices",
  },
  {
    id: 2,
    title: "Offices 02",
    imageUrl: Of4,
    link: "/commercial/offices",
  },
  {
    id: 3,
    title: "Offices 03",
    imageUrl: Of3,
    link: "/commercial/offices",
  },
  {
    id: 4,
    title: "Offices 04",
    imageUrl: Of7,
    link: "/commercial/offices",
  },
  {
    id: 5,
    title: "Offices 05",
    imageUrl: Of8,
    link: "/commercial/offices",
  },
  {
    id: 6,
    title: "Offices 05",
    imageUrl: Of9,
    link: "/commercial/offices",
  },
  // {
  //   id: 7,
  //   title: "Offices 05",
  //   imageUrl: Of8,
  //   link: "/commercial/offices",
  // },
  // {
  //   id: 8,
  //   title: "Offices 05",
  //   imageUrl: Of8,
  //   link: "/commercial/offices",
  // },
  // {
  //   id: 9,
  //   title: "Offices 05",
  //   imageUrl: Of9,
  //   link: "/commercial/offices",
  // },
  {
    id: 10,
    title: "Offices 05",
    imageUrl: Of10,
    link: "/commercial/offices",
  },
  {
    id: 11,
    title: "Offices 05",
    imageUrl: Of11,
    link: "/commercial/offices",
  },
  {
    id: 12,
    title: "Offices 05",
    imageUrl: Of12,
    link: "/commercial/offices",
  },
  {
    id: 13,
    title: "Offices 05",
    imageUrl: Of13,
    link: "/commercial/offices",
  },
  {
    id: 14,
    title: "Offices 05",
    imageUrl: Of14,
    link: "/commercial/offices",
  },
  {
    id: 15,
    title: "Offices 05",
    imageUrl: Of15,
    link: "/commercial/offices",
  },
  {
    id: 16,
    title: "Offices 05",
    imageUrl: Of16,
    link: "/commercial/offices",
  },
  {
    id: 17,
    title: "Offices 05",
    imageUrl: Of17,
    link: "/commercial/offices",
  },
  {
    id: 18,
    title: "Offices 05",
    imageUrl: Of18,
    link: "/commercial/offices",
  },
  {
    id: 19,
    title: "Offices 05",
    imageUrl: Of19,
    link: "/commercial/offices",
  },
  {
    id: 20,
    title: "Offices 05",
    imageUrl: Of20,
    link: "/commercial/offices",
  },
  {
    id: 21,
    title: "Offices 05",
    imageUrl: Of21,
    link: "/commercial/offices",
  },
  {
    id: 22,
    title: "Offices 05",
    imageUrl: Of22,
    link: "/commercial/offices",
  },
  {
    id: 23,
    title: "Offices 05",
    imageUrl: Of23,
    link: "/commercial/offices",
  },
  {
    id: 24,
    title: "Offices 05",
    imageUrl: Of24,
    link: "/commercial/offices",
  },
  {
    id: 25,
    title: "Offices 05",
    imageUrl: Of25,
    link: "/commercial/offices",
  },
  {
    id: 26,
    title: "Offices 05",
    imageUrl: Of26,
    link: "/commercial/offices",
  },
  {
    id: 27,
    title: "Offices 05",
    imageUrl: Of27,
    link: "/commercial/offices",
  },
  {
    id: 28,
    title: "Offices 05",
    imageUrl: Of28,
    link: "/commercial/offices",
  },
  // {
  //   id: 29,
  //   title: "Offices 05",
  //   imageUrl: Of29,
  //   link: "/commercial/offices",
  // },
  // {
  //   id: 30,
  //   title: "Offices 05",
  //   imageUrl: Of30,
  //   link: "/commercial/offices",
  // },
  // {
  //   id: 31,
  //   title: "Offices 05",
  //   imageUrl: Of31,
  //   link: "/commercial/offices",
  // },
  // {
  //   id: 32,
  //   title: "Offices 05",
  //   imageUrl: Of32,
  //   link: "/commercial/offices",
  // },
];
const RetailsDetails = [
  {
    id: 1,
    title: "Retails 01",
    imageUrl: Re1,
    link: "/commercial/retails",
  },
  {
    id: 2,
    title: "Retails 02",
    imageUrl: Re2,
    link: "/commercial/retails",
  },
  {
    id: 3,
    title: "Retails 03",
    imageUrl: Re3,
    link: "/commercial/retails",
  },
  {
    id: 4,
    title: "Retails 04",
    imageUrl: Re4,
    link: "/commercial/retails",
  },
  {
    id: 5,
    title: "Retails 05",
    imageUrl: Re5,
    link: "/commercial/retails",
  },
  {
    id: 6,
    title: "Retails 05",
    imageUrl: Re6,
    link: "/commercial/retails",
  },
  {
    id: 7,
    title: "Retails 05",
    imageUrl: Re7,
    link: "/commercial/retails",
  },
  // {
  //   id: 8,
  //   title: "Retails 05",
  //   imageUrl: Re8,
  //   link: "/commercial/retails",
  // },
  {
    id: 9,
    title: "Retails 05",
    imageUrl: Re9,
    link: "/commercial/retails",
  },
  {
    id: 10,
    title: "Retails 05",
    imageUrl: Re11,
    link: "/commercial/retails",
  },
  {
    id: 11,
    title: "Retails 05",
    imageUrl: Re12,
    link: "/commercial/retails",
  },
  {
    id: 12,
    title: "Retails 05",
    imageUrl: Re13,
    link: "/commercial/retails",
  },
  {
    id: 13,
    title: "Retails 05",
    imageUrl: Re14,
    link: "/commercial/retails",
  },
  {
    id: 14,
    title: "Retails 05",
    imageUrl: Re15,
    link: "/commercial/retails",
  },
  {
    id: 15,
    title: "Retails 05",
    imageUrl: Re16,
    link: "/commercial/retails",
  },
  {
    id: 16,
    title: "Retails 05",
    imageUrl: Re17,
    link: "/commercial/retails",
  },
  {
    id: 17,
    title: "Retails 05",
    imageUrl: Re18,
    link: "/commercial/retails",
  },
  {
    id: 18,
    title: "Retails 05",
    imageUrl: Re19,
    link: "/commercial/retails",
  },
  {
    id: 19,
    title: "Retails 05",
    imageUrl: Re20,
    link: "/commercial/retails",
  },
  {
    id: 20,
    title: "Retails 05",
    imageUrl: Re21,
    link: "/commercial/retails",
  },
];

export {
  bannerDetails,
  sectorDetails,
  sectorDetailsSettings,
  OfficesDetails,
  RetailsDetails,
  bannerDetailsRetail
};
