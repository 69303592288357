import React, { useEffect, useRef, useState } from "react";

//Owl Carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//MUI
import theme from "../../../Theme";
import { Box, Typography, SvgIcon, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme, useMediaQuery } from "@mui/material";

//Layouts
import LayoutTestimonials from "./Layouts/LayoutTestimonials";
import LayoutImageTitle from "./Layouts/LayoutImageTitle";
import LayoutImageTitleCont from "./Layouts/LayoutImageTitleCont";
import LayoutBgImageTitleCont from "./Layouts/LayoutBgImageTitleCont";
import LayoutImageTitleOver from "./Layouts/LayoutImageTitleOver";
import LayoutImageOnly from "./Layouts/LayoutImageOnly";

//Icons
import { arrow } from "../../../Assets/icons/icons";
import LayoutWhyChooseUs from "./Layouts/LayoutWhyChooseUs";
import useDeviceSize from "../../../Service/device-size";
const useStyles = makeStyles({
  StyledList: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginBottom: "40px",
    "& li": {
      listStyleType: "none",

      "& span, a": {
        display: "block",
        fontSize: "14px",
        listStyleType: "none",
        padding: "10px 25px",
        backgroundColor: "#D3DCDC",
        borderRadius: "50px",
        color: `rgba(${theme.palette.text.default},1)`,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      },
      "& a": {
        textDecoration: "none",
        backgroundColor: "#A8BFBF",
        transition: "all ease 1s",
        "&:hover": {
          backgroundColor: "#D3DCDC",
        },
      },
    },
  },
  StyledListWhatWeOffer: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginBottom: "40px",
    "& li": {
      listStyleType: "none",

      "& span, a": {
        display: "block",
        fontSize: "14px",
        listStyleType: "none",
        padding: "10px 25px",
        backgroundColor: "rgba(234, 190, 163, 1)",
        borderRadius: "50px",
        color: `rgba(${theme.palette.text.default},1)`,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      },
      "& a": {
        textDecoration: "none",
        // backgroundColor: "#A8BFBF",
        transition: "all ease 1s",
        "&:hover": {
          backgroundColor: "rgba(234, 190, 163, 1)",
        },
      },
    },
  },
});

const getUniqueCategoriesWithLinks = (data) => {
  const uniqueCategories = [];
  data?.forEach((item) => {
    const existingCategory = uniqueCategories.find(
      (category) => category.name === item.category
    );
    console.log(item.category);
    if (existingCategory && item.categoryLink) {
      // Use categoryLink if available
      existingCategory.categoryLink = item.categoryLink;
    } else if (!existingCategory && item.categoryLink) {
      uniqueCategories.push({
        name: item.category,
        categoryLink: item.categoryLink,
      });
    } else if (!existingCategory) {
      uniqueCategories.push({
        name: item.category,
      });
    }
  });
  console.log(data);
  return uniqueCategories;
};

const CustomCarouselMain = ({
  linkShow,
  data,
  settings = {},
  type,
  showbutton,
  showdesc,
  showcategories,
  linkcategories,
  sectionmargin,
  asratio,
}) => {
  const { isMobile } = useDeviceSize();
  //Custom Style
  const classes = useStyles();
  //Find Mui Break point
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const uniqueCategories = getUniqueCategoriesWithLinks(data);
  const {
    items,
    margin,
    loop,
    center,
    mouseDrag,
    touchDrag,
    nav,
    dots,
    autoplay,
    autoplayTimeout,
    autoplayHoverPause,
    responsive: responsive,
  } = { ...CustomCarouselMain.defaultProps.settings, ...settings };

  // Reference to the OwlCarousel instance
  const owlCarouselRef = useRef(null);

  const handlePrevClick = () => {
    if (owlCarouselRef.current) {
      // Go to the previous slide
      owlCarouselRef.current.prev();
    }
  };

  const handleNextClick = () => {
    if (owlCarouselRef.current) {
      // Go to the next slide
      owlCarouselRef.current.next();
    }
  };

  // Find That Scroller on that Section
  const sliderSectionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  console.log(isInView);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the slider is in view
    );

    if (sliderSectionRef.current) {
      observer.observe(sliderSectionRef.current);
    }

    return () => {
      if (sliderSectionRef.current) {
        observer.unobserve(sliderSectionRef.current);
      }
    };
  }, []);

  //  SliderLayout
  const SliderLayout = ({
    name,
    location,
    rating,
    title,
    image,
    content,
    desc,
    details = {},
    category,
    link,
    asratio,
  }) => {
    if (type === "testimonials") {
      return (
        <LayoutTestimonials
          name={name}
          location={location}
          rating={rating}
          title={title}
          image={data.image}
          content={content}
          desc={desc}
        />
      );
    } else if (type === "imagetitle") {
      return (
        <React.Fragment>
          <LayoutImageTitle
            link={link}
            image={image}
            title={title}
            desc={desc}
            asratio={asratio}
            showdesc={showdesc}
          />
        </React.Fragment>
      );
    } else if (type === "imagetitlecont" || type === "imagetitlecontOne") {
      return (
        <React.Fragment>
          <LayoutImageTitleCont
            link={link}
            image={image}
            asratio={asratio}
            title={title}
            desc={desc}
            showdesc={showdesc}
            details={details}
          />
        </React.Fragment>
      );
    } else if (type === "bgimagetitlecont") {
      return (
        <React.Fragment>
          <LayoutBgImageTitleCont
            link={link}
            image={image}
            title={title}
            desc={desc}
            asratio={asratio}
            showdesc={showdesc}
            details={details}
          />
        </React.Fragment>
      );
    } else if (type === "imagetitleover") {
      return (
        <React.Fragment>
          <LayoutImageTitleOver
            link={link}
            image={image}
            title={title}
            desc={desc}
            asratio={asratio}
            showdesc={showdesc}
            details={details}
          />
        </React.Fragment>
      );
    } else if (type === "WhyChoose") {
      return (
        <React.Fragment>
          <LayoutWhyChooseUs
            name={name}
            location={location}
            rating={rating}
            title={title}
            image={data.image}
            content={content}
            desc={desc}
            minH={"228px"}
          />
        </React.Fragment>
      );
    } else {
      {console.log(linkShow)}
      return (
        <React.Fragment>
          <LayoutImageOnly
            // link={linkShow === "hide" ? "" : link}
            image={image}
            title={title}
            desc={desc}
            asratio={asratio}
            showdesc={showdesc}
            details={details}
          />
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          margin: sectionmargin,
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridColumnGap: "20px",
        }}
      >
        {showbutton && isMd ? (
          <Box sx={{ gridArea: "1 / 1 / 2 / 2", alignSelf: "end" }}>
            <Box sx={{ margin: "0 0 50px 30px" }}>
              {/* Buttons    */}
              <Button
                className="custom-left-arrow"
                color="gray"
                sx={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                }}
                onClick={handlePrevClick}
              >
                <SvgIcon component={arrow} sx={{ rotate: "180deg" }} />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    textTransform: "initial",
                  }}
                >
                  Prev
                </Typography>
              </Button>
              {/* Buttons    */}
              <Button
                color="text"
                sx={{
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                }}
                onClick={handleNextClick}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    textTransform: "initial",
                  }}
                >
                  Next
                </Typography>
                <SvgIcon component={arrow} />
              </Button>
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            gridArea: showbutton && isMd ? "1 / 2 / 2 / 13" : "1 / 1 / 2 / 13",
          }}
        >
          {console.log(type)}
          {showcategories && uniqueCategories !== "" ? (
            <ul
              className={
                type === "imagetitlecont"
                  ? classes.StyledListWhatWeOffer
                  : classes.StyledList
              }
            >
              {console.log(uniqueCategories)}
              {uniqueCategories.map((category) => (
                <li
                  key={category.name}
                  style={{
                    textWrap: "nowrap",
                    flex: isMobile ? "1 1 calc(50% - 10px)" : "",
                  }}
                >
                  {console.log(category.categoryLink)}
                  {category.categoryLink ? (
                    <a
                      href={category.categoryLink}
                      style={{
                        padding: isMobile ? "10px 15px 10px 15px" : "10px 20px",
                      }}
                    >
                      {category.name}
                    </a>
                  ) : (
                    <span>{category.name}</span>
                  )}
                </li>
              ))}
            </ul>
          ) : null}
          {console.log(isInView)}
          <div ref={sliderSectionRef}>
            <OwlCarousel
              className="owl-theme"
              items={items}
              margin={margin}
              loop={loop}
              center={center}
              mouseDrag={mouseDrag}
              touchDrag={touchDrag}
              nav={nav}
              dots={dots}
              autoplay={isInView}
              // autoplay={autoplay}
              ref={owlCarouselRef}
              autoplayTimeout={autoplayTimeout}
              autoplayHoverPause={autoplayHoverPause}
              responsive={responsive}
              //autoHeight={true}
            >
              {data.map((item, key) => (
                <SliderLayout
                  name={item.name}
                  location={item.location}
                  rating={item.rating}
                  title={item.title}
                  image={item.imageUrl}
                  content={item.content}
                  desc={item.desc}
                  details={item.details}
                  category={item.category}
                  link={item.link}
                  key={key} // Add key prop to avoid React warning
                  asratio={asratio} // Add key prop to avoid React warning
                />
              ))}
            </OwlCarousel>
          </div>{" "}
        </Box>
      </Box>
    </React.Fragment>
  );
};

CustomCarouselMain.defaultProps = {
  settings: {
    items: 5,
    margin: 20,
    loop: true,
    center: false,
    mouseDrag: true,
    touchDrag: true,
    nav: false,
    dots: false,
    autoplay: true,
    //autoplayTimeout: 5000,
    autoplayTimeout: Math.floor(Math.random() * (5000 - 4000 + 1)) + 4000, // Random value between 4000 and 5000
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1.3,
      },

      600: {
        items: 2.3,
      },
      900: {
        items: 3.3,
      },
      1200: {
        items: 4.3,
      },
      1536: {
        items: 5.3,
      },
    },
  },

  type: "default", //show only Image
  showbutton: false,
  showdesc: false,
  showcategories: false,
  linkcategories: false,
  className: "owl-custom",
  sectionmargin: "40px 0",
  asratio: "1/1",
};

export default CustomCarouselMain;
