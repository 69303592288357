const Faqs = [
  {
    id: 1,
    title:
      "This is my first time working with an interior designer. How do we get started?",
    content: {
      ans01:
        "Our journey commences with an initial consultation, either in person or through a convenient phone or video call, where we dive into understanding your unititle project requirements. After that, we present an initial estimate to outline the cost and specifications. When you're satisfied with the estimate, we progress to crafting 2D and 3D designs, granting you a vivid glimpse of your envisioned space. Once the designs receive your seal of approval, we swiftly move on to our in-house furniture production and project execution phase.",
    },
  },
  {
    id: 2,
    title:
      "How long will it take for your interior design company to complete the project?",
    content: {
      ans01:
        "After finalizing the designs, expect your project completion and handover within 35-45 working days. Stay updated via WhatsApp or email throughout the home interior design and execution process until the final handover.Do you undertake commercial as well as residential interior projects?",
    },
  },
  {
    id: 3,
    title: "Why should someone go for customized interior design?",
    content: {
      ans01:
        "Say goodbye to one-size-fits-all designs! Embrace the magic of customized home interiors that seamlessly blend with your environment, unititle features, and personal tastes. Opt for tailored interior designs that reflect your style and perfectly complement your home's interiors.",
    },
  },
  {
    id: 4,
    title:
      "How much of my personal time would I need to invest in an interior design project?",
    content: {
      ans01:
        "With Modulin , no need to oversee the project personally. Just verify and approve the designs provided by our experts. Our skilled team takes care of everything from start to handover.",
    },
  },
  {
    id: 5,
    title:
      "Do you provide after-sales support? How many years of warranty do you provide?",
    content: {
      ans01:
        "Rest assured, we provide lifetime support after project completion. For assistance, simply give us a call at +91 96666 6666, and our dedicated service team will promptly resolve any issues.",
      ans02:
        "Additionally, we offer an impressive 10-year warranty on all woodwork materials, covering manufacturing defects, if any, to ensure your peace of mind.",
    },
  },
  {
    id: 6,
    title: "Can you start my home interior design project immediately?",
    content: {
      ans01:
        "If you've completed the first base painting, we're ready to dive in! Just reach out to us by submitting a contact form or giving us a call, and we'll offer you a complimentary estimate after thoroughly understanding your specific requirements. Let's embark on this journey together and create your dream space with Modulin !.",
    },
  },
  {
    id: 7,
    title:
      "How Do I Make Sure that My Home Interior Project Will be Completed as Required?",
    content: {
      ans01:
        "At Modulin , our interior designers are highly skilled professionals dedicated to exceeding customer expectations in every aspect of their home interior needs. With an impressive experience of over 19 years and a systematic work approach, we, at Modulin , strive to deliver excellence in design, production, and execution. You can be confident that your journey towards your dream home will be a joyful and seamless experience, as we go the extra mile to ensure your utmost satisfaction.",
    },
  },
  {
    id: 8,
    title: "What are Some Benefits of Modern Modular Kitchen Design?",
    content: {
      ans01:
        "Modern modular kitchens offer optimal space utilization, customization, and easy maintenance. With sleek aesthetics and enhanced functionality, they add value to your home and promote a clutter-free and organized cooking experience.",
    },
  },
  {
    id: 9,
    title: "What are Some Commonly used Materials for a Modular Kitchen?",
    content: {
      ans01:
        "Commonly used materials for modular kitchens include MDF, plywood, HPL, acrylic, stainless steel, glass, engineered quartz, natural stone, PVC, and aluminum.",
    },
  },
  {
    id: 10,
    title: "Which Materials should I Choose for Kitchen Countertops?",
    content: {
      ans01:
        "Consider granite for durability and natural beauty, quartz for low maintenance, marble for timeless elegance, stainless steel for a modern look, and butcher block for a rustic charm. Choose based on your budget and desired kitchen style.",
    },
  },
  {
    id: 11,
    title: "Can modular kitchens sustain water damage?",
    content: {
      ans01:
        "Yes, modular kitchens can sustain water damage without proper sealing and maintenance. Choose water-resistant materials and upkeep to protect your kitchen.",
    },
  },
  {
    id: 12,
    title: "What is MDF and Marine PLY ?",
    content: {
      ans01:
        "MDF (Medium-Density Fiberboard) is an engineered wood product used in furniture making. Marine Plywood is a water-resistant plywood ideal for water-exposed environments.",
    },
  },
  {
    id: 13,
    title:
      "Do I have to buy the entire installation, or can I get individual parts?",
    content: {
      ans01:
        "Yes, you have to purchase the entire unit if you need us to carry out the installation, as our team wouldn’t be able to install a part of it. You do have the option to purchase parts individually if needed.",
    },
  },
  {
    id: 14,
    title: "How long does it take for everything to be delivered?",
    content: {
      ans01:
        "It would take us around 25 to 30 days to get a unit manufactured after receiving the advance payment and the deigns being accepted and signed off at your end.",
    },
  },
  {
    id: 15,
    title:
      "How do I make my small kitchen seem bigger while maximizing cooking and entertaining space?",
    content: {
      ans01:
        "Maximize your small kitchen's space by using light colors, open shelving, and minimalist design. Incorporate smart storage, reflective surfaces, and compact appliances. Maximize natural light and extend the space outdoors for entertaining.",
    },
  },
  {
    id: 16,
    title:
      "I have a new 3-bedroom House/Apartment. Can Modulin  do the complete home interiors for me?",
    content: {
      ans01:
        "Modulin  offers complete home interior solutions, including modular kitchens, living-dining areas, and bedrooms for houses and apartments. Elevate your living spaces with us.",
    },
  },
];

export { Faqs };
