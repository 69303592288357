import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../Component/Layout/DefaultLayout";
import InnerBannner from "../../../Component/HelperComponents/BannerComponent/InnerBanner";
import { Avatar, Grid, Typography } from "@mui/material";
import "./Profile.scss";
import ProfileImg from "../../../Assets/profile1.png";
import Boy from "../../../Assets/boy.jpg";
import Girl from "../../../Assets/girl.jpg";
import { Box } from "@mui/system";
import { AddDetailsModal } from "./AddDetailsModal";
import axios from "axios";
import { POST_USER_DETAIL } from "../../../Service/ApiRoutes";
import { AddDetailsAgentModal } from "./AddDetailsAgentModal";
export const WebProfile = () => {
  const [ProfileData, setProfileData] = useState([]);
  const [ModalType, setModalType] = useState(ProfileData?.role);
  const [isShow, setisShow] = useState(false);
  const handleToggler = () => setisShow(!isShow);
  const Token = localStorage.getItem("TOKEN");
  const HandleGetProfile = () => {
    axios
      .post(
        POST_USER_DETAIL(),
        {},
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then(({ data }) => {
        if (data?.status === "Success") {
          setProfileData(data?.data[0]);
          console.log(data);
        }
      });
  };

  console.log(ProfileData?._id?.slice(-8));
  console.log(ProfileData?.gender);
  useEffect(() => {
    HandleGetProfile();
  }, []);
  console.log(ModalType);
  console.log(isShow && ModalType === "User");
  return (
    <React.Fragment>
      <DefaultLayout>
        {isShow && ModalType === "User" ? (
          <AddDetailsModal
            Toggler={handleToggler}
            Initial={ProfileData}
            Refetch={HandleGetProfile}
          />
        ) : (
          isShow && (
            <AddDetailsAgentModal
              Toggler={handleToggler}
              Initial={ProfileData}
              Refetch={HandleGetProfile}
            />
          )
        )}
        <Box className="ProfileWeb">
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            Profile{" "}
          </Typography>
          <Grid container>
            <Grid item lg="9" md="9">
              <Box
                sx={{
                  display: "flex",
                  // flexDirection: "column",
                  gap: "20px",
                  marginRight: "70px",
                  marginBottom: "0px",
                }}
              >
                <Avatar
                  variant="square"
                  sx={{
                    width: "100px",
                    height: "100px",
                    aspectRatio: "1/1",
                    border: "1px solid rgb(169, 165, 165)",
                    borderRadius: 2,
                  }}
                >
                  <img
                    src={ProfileData?.gender === "Male" ? Boy : Girl}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0px",
                  }}
                >
                  <Typography variant="small">
                    {ProfileData?._id?.slice(-8)?.toUpperCase()}
                  </Typography>
                  <Typography variant="h6">
                    {ProfileData?.first_name + " " + ProfileData?.last_name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg="3" md="3" className="FlexEnd" alignItems={"center"}>
              <button
                className="btnYellow"
                onClick={
                  ModalType === "User"
                    ? () => {
                        setModalType("User");
                        handleToggler();
                      }
                    : () => {
                        setModalType("Agent");
                        handleToggler();
                      }
                }
              >
                Add Details
              </button>
            </Grid>
            <div className="Candidate mt-3">
              <h6 className="heading">Personal Info</h6>
              <div className="Right_box">
                <div className="GridCandidate mt-2">
                  <div className="box">
                    <label>Agent Id </label>
                    <p> {ProfileData?._id?.slice(-8)?.toUpperCase()}</p>
                  </div>
                  <div className="box">
                    <label> First Name</label>
                    <p> {ProfileData?.first_name}</p>
                  </div>
                  <div className="box">
                    <label>Last Name </label>
                    <p> {ProfileData?.last_name}</p>
                  </div>

                  <div className="box">
                    <label>Phone No </label>
                    <p> {ProfileData?.mobile_num?.slice(-10)}</p>
                  </div>
                  <div className="box">
                    <label>Alternate No </label>
                    <p> {ProfileData?.alternate_num}</p>
                  </div>
                  <div className="box">
                    <label> Email ID</label>
                    <p> {ProfileData?.email}</p>
                  </div>
                  <div className="box">
                    <label>Gender </label>
                    <p> {ProfileData?.gender}</p>
                  </div>

                  <div className="box">
                    <label>Marital Status </label>
                    <p> {ProfileData?.marital_status}</p>
                  </div>
                </div>
                <div className="Grid2 mt-2">
                  <div className="box">
                    <label
                      style={{
                        fontSize: ".8em",
                        color: "#000",
                        fontWeight: 700,
                      }}
                    >
                      Current Address{" "}
                    </label>
                    <p className="mt-1"> {ProfileData?.current_address}</p>
                  </div>{" "}
                  <div className="box">
                    <label
                      style={{
                        fontSize: ".8em",
                        color: "#000",
                        fontWeight: 700,
                      }}
                    >
                      Parmanent Address{" "}
                    </label>
                    <p className="mt-1"> {ProfileData?.permanent_address}</p>
                  </div>
                </div>
              </div>
            </div>
            {ProfileData?.role === "Agent" && (
              <div className="Candidate mt-3">
                <h6 className="heading">Bank Details</h6>
                <div className="Right_box">
                  <div className="GridCandidate mt-2">
                    <div className="box">
                      <label> Bank Name</label>
                      <p> {ProfileData?.bank_name}</p>
                    </div>
                    <div className="box">
                      <label>Account Number </label>
                      <p> {ProfileData?.account_num}</p>
                    </div>

                    <div className="box">
                      <label>IFSC Code </label>
                      <p> {ProfileData?.ifsc_code}</p>
                    </div>
                    <div className="box">
                      <label>Holder Name </label>
                      <p> {ProfileData?.holder_name}</p>
                    </div>
                    <div className="box">
                      <label> Branch</label>
                      <p> {ProfileData?.branch}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid flex={1}></Grid>
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
};
