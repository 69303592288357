import React from 'react';

import {
  Box,
  Avatar,
  Typography,
  Stack,
  Toolbar,
  AppBar,
  Grid,
  SvgIcon,
  IconButton,
} from '@mui/material';
import theme from '../../../Theme';
import { menu } from '../../../Assets/icons/icons';

const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: `rgba(${theme.palette.text.white},1)` }}
    >
      <Toolbar>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid>
            <IconButton>
              {/* <SvgIcon component={menu} /> */}
            </IconButton>
          </Grid>
          <Grid>
            <Stack direction="row" alignItems="center" gap={1}>
              <Avatar>O</Avatar>
              <Box>
                <Typography>Omkar Sahu</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
