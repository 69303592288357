import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Frammer Motion
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { CgProfile } from "react-icons/cg";
import { AiOutlineLogin } from "react-icons/ai";
import { LuUserCircle } from "react-icons/lu";

//MUI
import theme from "../../Theme";
import {
  Container,
  Grid,
  SvgIcon,
  IconButton,
  Box,
  Drawer,
  ListItem,
  List,
  MenuItem,
  Menu,
  Avatar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Images and Icons
import Logo from "../../Assets/logo.png";
import { user, menu } from "../../Assets/icons/icons";
import { Close as CloseIcon } from "@mui/icons-material";
import { maxWidth } from "@mui/system";
import useDeviceSize from "../../Service/device-size";
import { FaSortDown } from "react-icons/fa6";

const OutSideMenu = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "Offices", link: "/offices" },
  { id: 3, name: "Retail", link: "/retail" },
  { id: 4, name: "Design Ideas", link: "/design-ideas" },
];
const OutSideMenu1 = [
  // { id: 5, name: "Partner with Us", link: "/partner-with-us" },
  { id: 6, name: "Refer A Customer", link: "/refer-a-customer" },
  { id: 7, name: "More" },
];

const MenuItems = [
  { id: 1, name: "Residence", link: "/design-ideas" },
  { id: 2, name: "Offices", link: "/offices" },
  { id: 6, name: "Retail", link: "/retail" },
];
const MenuItemsSmall = [
  { id: 3, name: "Refer", link: "/refer-a-customer" },
  // { id: 3, name: "Cities", link: "/city/kakinada" },
  { id: 4, name: "Careers", link: "/careers" },
  { id: 5, name: "Contact Us", link: "/contact-us" },
  // { id: 6, name: "Partner with Us", link: "/partner-with-us" },
];

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
    top: "0",
    zIndex: "99",
  },

  headerwrapper: {
    position: "absolute",
    width: "calc(100%)",
    boxSizing: "border-box",
    overflow: "hidden",
    left: 0,
    transition: "background-color ease 0.5s", // Add the transition property here
    // backgroundColor: "transparent", // Use MUI theme background color
    backgroundColor: "rgba(255, 255, 255, 1)", // Use MUI theme background color
  },

  hasAddBg: {
    backgroundColor: `rgba(${theme.palette.text.white},.95)`, // Or any other color you prefer
  },

  list: {
    marginBottom: "00px",
    //justifyContent: "center",
  },
  listSmall: {
    marginBottom: "0px",
    //justifyContent: "center",
  },
  menuitem: {
    textDecoration: "none",
    fontSize: "45px",
    fontWeight: "800",
    textAlign: "center",
    color: `rgba(${theme.palette.primary.default},1)`,

    "&:hover": {
      color: `rgba(${theme.palette.primary.default},.5)`,
    },
  },
  OutsideList: {
    fontWeight: "700",
    color: `${theme.palette.text.orange1}`,
    textDecoration: "none",
  },
  drop: {
    fontWeight: "700",
    color: "black",
    textDecoration: "none",
  },
  menuitemSmall: {
    textDecoration: "none",
    fontSize: "30px",
    fontWeight: "600",
    textAlign: "center",
    color: `rgba(${theme.palette.primary.default},1)`,

    "&:hover": {
      color: `rgba(${theme.palette.primary.default},.5)`,
    },
  },
  menudrawer: {
    "& .MuiBackdrop-root": {
      // Customize backdrop styles here
      backgroundColor: "rgba(0, 0, 0, 0.2)", // Change this to your desired color
    },
  },
}));

const Header = () => {
  const Token = localStorage.getItem("TOKEN");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElMore, setAnchorElMore] = useState(null);
  const openMore = Boolean(anchorElMore);
  const handleClickMore = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const handleCloseMore = () => {
    setAnchorElMore(null);
  };
  const classes = useStyles();
  const { isMobile, isTab } = useDeviceSize();
  // State to manage header visibility
  const [hidden, setHidden] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [hasAddBg, setHasAddBg] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle scroll events and update header visibility
  function update() {
    const currentScrollY = window.scrollY;
    if (currentScrollY < prevScrollY) {
      setHidden(false);
    } else if (currentScrollY > 200 && currentScrollY > prevScrollY) {
      // setHidden(true);
      setHidden(false);
    }
    setPrevScrollY(currentScrollY);

    // Add or remove addbg class based on scroll position
    if (currentScrollY > 300) {
      // setHasAddBg(true);
      setHasAddBg(false);
    } else {
      setHasAddBg(false);
    }
  }

  // Effect to add and remove the scroll event listener
  useEffect(() => {
    function handleScroll() {
      update();
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  // Animation variants for header motion
  const headerVariants = {
    hidden: { y: -81 },
  };

  return (
    <React.Fragment>
      {/* Header component */}
      <header className={classes.header}>
        {/* Motion div for header animation */}
        <motion.div
          className={`${classes.headerwrapper} ${
            hasAddBg ? classes.hasAddBg : ""
          }`}
          variants={headerVariants}
          animate={hidden ? "hidden" : "visible"}
          initial="visible"
        >
          {/* Container for header content */}
          <Container maxWidth={false}>
            <Grid
              container
              sx={{
                padding: "18px 0",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                {/* Logo linking to home page */}
                <Box display={"flex"} gap={"60px"} alignItems={"center"}>
                  <Box component={Link} to="/">
                    <img
                      src={Logo}
                      alt="Logo"
                      // width={"120%"}
                      style={{
                        width: isMobile ? "110%" : "120%",
                        marginTop: isMobile ? 4 : 0,
                      }}
                    />
                  </Box>
                  {isMobile ? (
                    ""
                  ) : isTab ? (
                    ""
                  ) : (
                    <Grid>
                      <Box>
                        <List
                          sx={{
                            display: "flex",
                          }}
                        >
                          {OutSideMenu.map(({ name, link, id }) => (
                            <ListItem
                              // className={classes.list}
                              component={motion.li}
                              key={id}
                              initial={{ opacity: 0, x: -50 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -50 }}
                              transition={{ duration: 0.4, delay: id * 0.15 }}
                            >
                              <Link
                                key={id}
                                to={link}
                                className={classes.OutsideList}
                                style={{
                                  textWrap: "nowrap",
                                }}
                              >
                                {name}
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid>
                {console.log(isMobile)}
                <Box display={"flex"} gap={"50px"} alignItems={"center"}>
                  {isMobile ? (
                    ""
                  ) : isTab ? (
                    ""
                  ) : (
                    <Box>
                      <List
                        sx={{
                          display: "flex",
                        }}
                      >
                        {OutSideMenu1.map(({ name, link, id }) => (
                          <>
                            <ListItem
                              // className={classes.list}
                              component={motion.li}
                              key={id}
                              initial={{ opacity: 0, x: -50 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -50 }}
                              transition={{ duration: 0.4, delay: id * 0.15 }}
                            >
                              {link ? (
                                <Link
                                  key={id}
                                  to={link}
                                  className={classes.OutsideList}
                                  style={{
                                    textWrap: "nowrap",
                                  }}
                                >
                                  {name}
                                </Link>
                              ) : (
                                <Typography
                                  className={classes.OutsideList}
                                  sx={{
                                    fontWeight: 700,
                                  }}
                                  onClick={handleClickMore}
                                >
                                  {name}
                                  <FaSortDown />
                                </Typography>
                              )}
                            </ListItem>
                            <div>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElMore}
                                open={openMore}
                                onClose={handleCloseMore}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                slotProps={{
                                  paper: {
                                    elevation: 0,
                                    sx: {
                                      overflow: "visible",
                                      filter:
                                        "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                      mt: 1.5,
                                      "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                      },
                                      "& svg": {
                                        width: 20,
                                        height: 20,
                                        ml: -0.5,
                                        mr: 1,
                                      },
                                      "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                          "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                      },
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Link
                                  to={"/contact-us"}
                                  className={classes.drop}
                                >
                                  <MenuItem onClick={handleClose}>
                                    Contact Us
                                  </MenuItem>
                                </Link>{" "}
                                <Link to={"/careers"} className={classes.drop}>
                                  <MenuItem>Career</MenuItem>
                                </Link>{" "}
                              </Menu>
                            </div>
                          </>
                        ))}
                      </List>
                    </Box>
                  )}
                  <Grid className="FlexCenter">
                    {/* IconButton linking to login page */}
                    {/* <CgProfile
                      style={{ fontSize: "1.6em", }}
                    /> */}
                    <IconButton component={Link} to="">
                      <IconButton component={Link}>
                        <SvgIcon component={user} onClick={handleClick} />
                      </IconButton>
                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          slotProps={{
                            paper: {
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "& svg": {
                                  width: 20,
                                  height: 20,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Link
                            to={Token ? "/profile" : "/login"}
                            className={classes.drop}
                          >
                            <MenuItem onClick={handleClose}>
                              {" "}
                              <LuUserCircle /> Profile
                            </MenuItem>
                          </Link>{" "}
                          <Link to={"/login"} className={classes.drop}>
                            <MenuItem
                              onClick={
                                Token
                                  ? () => {
                                      localStorage.clear();
                                    }
                                  : handleClose
                              }
                            >
                              <AiOutlineLogin />
                              {!Token ? "Login" : "Logout"}
                            </MenuItem>
                          </Link>{" "}
                        </Menu>
                      </div>
                    </IconButton>
                    {/* IconButton for menu (possibly a mobile menu) */}

                    <IconButton onClick={toggleDrawer}>
                      <SvgIcon component={menu} />
                    </IconButton>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </motion.div>
      </header>

      {/* //Main Menu        */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }} // Adjust the overlay transition duration
          >
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              anchor="right"
              PaperProps={{
                component: motion.div,
                initial: { width: 0 },
                animate: {
                  width: isMobile ? "80%" : "50%",
                  backgroundColor: "white",
                  minWidth: 400,
                  maxWidth: 450,
                },
                exit: { width: 0, minWidth: 0 },
                transition: {
                  duration: 0.4, // Adjust the drawer transition duration
                  ease: "easeInOut", // You can also try other easing functions for smoother transitions
                },
              }}
              ModalProps={{
                keepMounted: false,
              }}
              className={classes.menudrawer}
            >
              <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
                <IconButton onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"80px"}>
                  <List>
                    {MenuItems.map(({ name, link, id }) => (
                      <ListItem
                        className={classes.list}
                        component={motion.li}
                        key={id}
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.4, delay: id * 0.15 }}
                      >
                        <Link key={id} to={link} className={classes.menuitem}>
                          {name}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                  <List sx={{ mt: 2 }}>
                    {MenuItemsSmall.map(({ name, link, id }) => (
                      <ListItem
                        className={classes.listSmall}
                        component={motion.li}
                        key={id}
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.4, delay: id * 0.15 }}
                      >
                        <Link
                          key={id}
                          to={link}
                          className={classes.menuitemSmall}
                        >
                          {name}
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Drawer>
          </motion.div>
        )}
      </AnimatePresence>
      {/* //Main Menu End        */}
    </React.Fragment>
  );
};

export default Header;
