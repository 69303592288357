import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { UserTable } from "./UserTable";
import { POST_USER_AGENTS_ADMIN } from "../../../Service/ApiRoutes";
import axios from "axios";

const Users = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "slno",
      headerName: "Sl.No",
      minWidth: 150,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,
    },
    {
      field: "formtype",
      headerName: "Form Type",
      minWidth: 120,
      //editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
    },
  ];

  const rows = [
    {
      id: 1,
      slno: "bd125364854823",
      date: "10/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 2,
      slno: "c125364853987",
      date: "09/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 3,
      slno: "aa564857845632",
      date: "08/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 4,
      slno: "ef125364854154",
      date: "07/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 5,
      slno: "bd125364854823",
      date: "06/07/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 6,
      slno: "gd235469823641",
      date: "05/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 7,
      slno: "cd125364854135",
      date: "04/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 8,
      slno: "bh125364857896",
      date: "03/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 9,
      slno: "ed125364854823",
      date: "02/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 10,
      slno: "bd125364854823",
      date: "01/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 11,
      slno: "bc125364854821",
      date: "30/06/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 12,
      slno: "ca125364856503",
      date: "29/06/2023",
      formtype: "Quote",
      status: "Cancelled",
    },
    {
      id: 13,
      slno: "bg125364854821",
      date: "28/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 14,
      slno: "be125364854820",
      date: "27/06/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 15,
      slno: "ba125364856784",
      date: "26/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
  ];
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [workProfile, setworkProfile] = useState("");
  const [UserAgentData, setUserAgentData] = useState([]);
  const HandleGetUSerAgents = () => {
    const Payload = {
      workProfile: workProfile,
      status: "",
    };
    axios
      .post(POST_USER_AGENTS_ADMIN(), Payload, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setUserAgentData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [workProfile]);
  console.log(UserAgentData);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Users
        </Typography>
        <div
          className=""
          style={{
            maxWidth: "150px",
            marginTop: 2,
          }}
        >
          <label
            htmlFor="leaveId"
            style={{
              fontSize: ".9em",
            }}
            className="mb-2 fw500"
          >
            Work Profile
          </label>
          <FormControl fullWidth className="CustomSelectDropSmallGray">
            <InputLabel id="leaveId">Select</InputLabel>
            <Select
              labelId="leaveId"
              id="demo-simple-leave"
              label="Select"
              value={workProfile}
              name="loan_type"
              className="Custom_Select"
            >
              {[
                "Architect",
                "Interior Designer",
                "Civil Engineer",
                "Carpenter",
                "Marketing Agent",
                "Client",
                "Others",
              ]?.map((Elem) => {
                return (
                  <MenuItem
                    value={Elem}
                    key={Elem}
                    onClick={() => {
                      setworkProfile(Elem);
                    }}
                  >
                    {Elem}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <UserTable Data={UserAgentData?.total_users} />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Users;
