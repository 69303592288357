import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../Theme";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const CopyRights = ({ textcol }) => {
  const currentYear = new Date().getFullYear();

  const CopyRightsDiv = styled(Typography)({
    color: textcol,
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "center",
      //  paddingLeft : "-10px",
    "& a": {
      color: textcol,
      textDecoration: "none",
      "&:hover": {
        color: `rgba(${theme.palette.primary.default},1)`,
      },
    },
  });

  return (
    <React.Fragment>
      <CopyRightsDiv>
        &#169; {currentYear} <Link to="#">modulin.in </Link>
        All Rights Reserved
      </CopyRightsDiv>
    </React.Fragment>
  );
};

CopyRights.defaultProps = {
  textcol: "#ffffff",
};

export default CopyRights;
