import React, { useState } from "react";
import { Link } from "react-router-dom";

//MUI
import theme from "../../../Theme";
import {
  Box,
  Typography,
  List,
  ListItem,
  Grid,
  SvgIcon,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//Images and Icons
import { arrow } from "../../../Assets/icons/icons";
import { padding } from "@mui/system";

const useStyles = makeStyles({
  linkWrapper: {
    color: `rgba(${theme.palette.text.default},1)`,
    textDecoration: "none",
    display: "block",
  },
  imgContentDivWrapper: {
    backgroundColor: `rgba(${theme.palette.text.white},.8)`,
    "&:hover $imgDiv": { transform: "scale(1.2)" },
    "&:hover $titleOver": {
      top: "90%",
      left: "50%",
      transform: "translate(-50%, -90%)",
      "& :after": {
        width: "70%",
      },
    },
    "&:hover $imgDivSlide": {
      backgroundPosition: "100% 0",
      curser: "pointer",
    },
  },
  imgDivWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  imgDiv: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover", // or 'contain' for different scaling behavior
    backgroundPosition: "center center",
    transition: "all ease 1s",
  },
  imgDivSlide: {
    transition: "all ease 1s",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "0 0",
  },
  titleOver: {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: "translate(-50%, -80%)",
    color: `rgba(${theme.palette.text.default},1)`,
    transition: "all ease 1s",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    width: "90%",
    paddingBottom: "8px",

    "& span": {
      fontWeight: 200,
    },
    "& :after": {
      display: "block",
      content: "''",
      borderBottom: `2px solid red`,
      width: "30%",
      position: "absolute",
      bottom: "0%",
      left: "50%",
      transform: "translate(-50%, 0%)",
      transition: "all ease 1s",
    },
  },
  getLink: {
    position: "absolute",
    bottom: "20%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    "& p": {
      textDecoration: "none",
      backgroundColor: `rgba(${theme.palette.primary.default},1)`,
      padding: "12px 25px",
      borderRadius: "5px",
      color: `rgba(${theme.palette.text.white},1)`,
      cursor: "pointer",
    },
  },

  contentDivWrapper: {
    padding: "20px",
    boxSizing: "border-box",
  },

  StyledList: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    marginBottom: "20px",
    justifyContent: "center",
    listStyleType: "none", // Remove bullets from the list
  },
  listItem: {
    fontSize: "14px",
    listStyleType: "none",
    padding: "10px 25px",
    backgroundColor: "#D3DCDC",
    borderRadius: "50px",
    color: `rgba(${theme.palette.text.default},1)`,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center", // Center the link vertically
    textDecoration: "none", // Remove default underline for link
  },
});

const CustomGallery = ({
  data,
  type,
  margin,
  showbutton,
  showcategories,
  handleClickOpen,
  loadmore,
}) => {
  const classes = useStyles(); //Custom Style
  // const uniqueCategories = [...new Set(data.map((item) => item.category))]; //unique Categories List
  const uniqueCategories = data.map((item) => {
    return { category: item.category, link: item.link };
  }); //unique Categories List

  const [visibleItems, setVisibleItems] = useState(10);
  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  };
  //

  const GalleryLayout = ({ title, image, content, category, link }) => {
    const titlemain = title.split(" ");
    const titlelast = titlemain.pop();

    const getTitleWithoutLastWord = (title) => {
      const words = title.split(" ");
      return words.slice(0, -1).join(" ");
    };

    const getLastWord = (title) => {
      const words = title.split(" ");
      return words[words.length - 1];
    };

    return (
      <React.Fragment>
        <Box
          component={link ? Link : "div"}
          to={link}
          className={classes.linkWrapper}
        >
          <Box
            className={classes.imgContentDivWrapper}
            sx={{
              boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Box
              className={classes.imgDivWrapper}
              sx={{
                aspectRatio: "1/1",
              }}
            >
              <Box
                className={
                  type !== "slideimage" ? classes.imgDiv : classes.imgDivSlide
                }
                sx={{
                  //backgroundImage: `url(${image})`,
                  backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.2) 70%, rgba(255,255,255,.2) 85%, rgba(255,255,255,1) 100%), url(${image})`,
                }}
              ></Box>
              {type !== "slideimage" ? (
                <p className={classes.titleOver}>
                  {getTitleWithoutLastWord(title)}

                  <span> {getLastWord(title)}</span>
                </p>
              ) : (
                <Box className={classes.getLink}>
                  <Typography
                    onClick={handleClickOpen}
                    style={{
                      textWrap :"nowrap"
                    }}
                  >
                    Get free Quote
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {showcategories ? (
        <Box>
          <ul className={classes.StyledList}>
            {uniqueCategories.map((item, index) => (
              <li key={index}>
                <Link to={item.link} className={classes.listItem}>
                  {item.category}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gap: "30px",
          [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {loadmore
          ? data.slice(0, visibleItems).map((item) => (
              <Box>
                <GalleryLayout
                  title={item.title}
                  image={item.imageUrl}
                  content={item.content}
                  details={item.details}
                  category={item.category}
                  link={item.link}
                />
              </Box>
            ))
          : data.map((item, key) => (
              <Box>
                <GalleryLayout
                  title={item.title}
                  image={item.imageUrl}
                  content={item.content}
                  details={item.details}
                  category={item.category}
                  link={item.link}
                />
              </Box>
            ))}
      </Box>
      {loadmore && visibleItems < data.length && (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
        >
          <Button
            variant="outlined"
            onClick={showMoreItems}
            sx={{ textTransform: "capitalize", padding: "15px 60px" }}
          >
            Load more
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
};

CustomGallery.defaultProps = {
  margin: "40px 0",
  showcategories: false,
  loadmore: false,
};

export default CustomGallery;
