import React, { useEffect, useState } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  linearProgressClasses,
  TablePagination,
} from "@mui/material";
import { FiEye } from "react-icons/fi";

import { tablePaginationClasses as classes } from "@mui/base/TablePagination";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line, RiFileList3Line } from "react-icons/ri";
import { borderBottom, Box, styled } from "@mui/system";
import theme from "../../../Theme";
import { ConvertValidDate } from "../../../Service/ConvertValidDate";
import { AddressModal } from "../../../Component/HelperComponents/Modal/ReasonModal";

export const ConsultationTable = ({
  TogglerEdit,
  TogglerDelete,
  setSingleTrainingData,
  Data,
  setType,
}) => {
  const [HandAsset, setHandAsset] = useState(false);
  const HandAssetsToggler = () => setHandAsset(!HandAsset);
  const [isReason, setisReason] = useState(false);
  const ToggleisReason = () => setisReason(!isReason);
  const [ReasonValue, setReasonValue] = useState(null);
  //   Pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setrows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  function createData(name, email, phone_number, description, date) {
    return {
      name,
      email,
      phone_number,
      description,
      date,
    };
  }
  useEffect(() => {
    setrows([]);
    if (Data) {
      const newRows = Data?.map((Elem) =>
        createData(
          Elem?.name,
          Elem?.email,
          Elem?.phone_number,
          Elem?.description,
          Elem?.createdAt
        )
      );
      setrows(newRows);
    }
  }, [Data]);

  console.log(Data);

  const [Checked, setChecked] = useState("");

  return (
    <>
    {isReason && (
        <AddressModal title={"Description"} value={ReasonValue} Toggler={ToggleisReason} />
      )}{" "}
      <Box
        className="table-border pt-0 mt-2"
        sx={
          {
            // backgroundColor: `rgba(${Theme.palette.background.tableRow1},.8)`,
          }
        }
      >
        <div className=" GlobalTable10 " style={{ borderBottom: "none" }}>
          <TableContainer className="m-0 p-0">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={cellStyleHeader}>S.no</TableCell>
                  <TableCell style={cellStyleHeader}>Date</TableCell>
                  <TableCell style={cellStyleHeader}>Name</TableCell>
                  <TableCell style={cellStyleHeader}>Email</TableCell>
                  <TableCell style={cellStyleHeader}>Phone</TableCell>
                  <TableCell style={cellStyleHeader}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell style={cellStyle}>{key + 1}</TableCell>
                      <TableCell style={cellStyle}>
                        {" "}
                        {ConvertValidDate(row?.date)}
                      </TableCell>
                      <TableCell style={cellStyle}> {row?.name}</TableCell>
                      <TableCell style={cellStyle}> {row?.email}</TableCell>
                      <TableCell style={cellStyle}>
                        {" "}
                        {row?.phone_number}
                      </TableCell>
                      <TableCell style={cellStyle}>
                        {" "}
                        <div
                          className="actions cursor"
                          onClick={() => {
                            setReasonValue(row?.description);
                            ToggleisReason();
                          }}
                        >
                          <RiFileList3Line className="fs-6" />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="Pagination_table py-2 px-4">
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                "aria-label": "Rows per page",
              },
              actions: {
                showFirstButton: false,
                showLastButton: false,
                slots: {
                  nextPageIcon: MdKeyboardArrowRight,
                  backPageIcon: MdKeyboardArrowLeft,
                },
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>
    </>
  );
};
const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiToolbar-root": {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px",
    backgroundColor: "#f4f4f4", // Custom background color
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "#333", // Custom label color
  },
  "& .MuiTablePagination-select": {
    border: "1px solid #ccc", // Custom select border
    padding: "5px",
    borderRadius: "5px",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "#333", // Custom text color
    fontSize: "0.9rem",
  },
  "& .MuiTablePagination-actions": {
    "& button": {
      color: "#1a73e8", // Custom pagination button color
    },
    "& button:hover": {
      backgroundColor: "#e3f2fd", // Hover effect on buttons
    },
  },
}));

// const CustomTablePagination = styled(TablePagination)(
//   ({ theme }) => `
//     & .${classes.spacer} {
//       display: block;
//     }

//     & .${classes.toolbar}  {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//       gap: 8px;
//       background-color: ${
//         theme.palette.mode === "dark" ? "transparent" : "#fff"
//       };

//       @media (min-width: 768px) {
//         flex-direction: row;
//         align-items: center;
//       }
//     }

//     & .${classes.selectLabel} {
//       margin: 0;
//       color: ${theme.palette.mode === "dark" ? "#fff" : grey[200]};
//     }
//     & .${classes.displayedRows} {
//       margin: 0;
//       color: ${theme.palette.mode === "dark" ? "#fff" : grey[200]};
//     }

//     & .${classes.select}{
//       font-family: 'IBM Plex Sans', sans-serif;
//       padding: 2px 0 2px 4px;
//       border: 1px solid ${theme.palette.mode === "dark" ? "#fff" : grey[200]};
//       border-radius: 2px;
//       background-color: ${theme.palette.mode === "dark" ? "#fff" : grey[900]} ;
//       color: ${theme.palette.mode === "dark" ? "#000" : grey[900]};
//       transition: all 100ms ease;

//       &:hover {
//         background-color: ${theme.palette.mode === "dark" ? "#fff" : grey[50]};
//         border-color: ${theme.palette.mode === "dark" ? "#fff" : grey[300]};
//       }

//       &:focus {
//         outline: 3px solid ${
//           theme.palette.mode === "dark" ? blue[400] : blue[200]
//         };
//         border-color: ${blue[400]};
//       }
//     }

//     & .${classes.displayedRows} {
//       margin: 0;

//       @media (min-width: 768px) {
//         margin-left: auto;
//       }
//     }

//     & .${classes.actions} {
//       display: flex;
//       gap: 6px;
//       color: ${theme.palette.mode === "dark" ? "#000" : grey[900]};
//       border: transparent;
//       text-align: center;
//     }

//     & .${classes.actions} > button {
//       display: flex;
//       align-items: center;
//       padding: 0;
//       border: transparent;
//       border-radius: 50%;
//       background-color: transparent;
//       border: none;
//       color: ${theme.palette.mode === "dark" ? "#fff" : grey[900]};
//       transition: all 100ms ease;

//       > svg {
//         font-size: 22px;
//       }

//       &:hover {
//         background-color: ${theme.palette.mode === "dark" ? "#fff" : grey[50]};
//         border-color: ${theme.palette.mode === "dark" ? "#fff" : grey[300]};
//       }

//       &:focus {
//         outline: 3px solid ${
//           theme.palette.mode === "dark" ? "#fff" : blue[200]
//         };
//         border-color: ${blue[400]};
//       }

//       &:disabled {
//         opacity: 0.3;
//         &:hover {
//           border: 1px solid ${
//             theme.palette.mode === "dark" ? "#fff" : grey[200]
//           };
//           background-color: transparent;
//         }
//       }
//     }
//     `
// );

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 18,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2BCB4E",
  },
}));
const btnHandOver = {
  fontSize: ".8em",
  padding: ".2em 1em",
  border: "none",
  borderRadius: "4px",
  fontWeight: 700,
  margin: "5px",
};

const cellStyle = {
  padding: "1px",
  fontSize: ".87em",
  textAlign: "center",
  // backgroundColor: `rgba(${Theme.palette.background.tableRow1},.8)`,
  borderBottom: "1px solid rgba(109, 109, 109, 0.657)",
};
const cellStyleHeader = {
  padding: "10px 8px",
  // borderColor: `rgba(${Theme.palette.text.white},.5)`, // You can change 'red' to your desired border color
  fontSize: 14.5,
  fontWeight: 600,
  textAlign: "center",
  borderBottom: "1px solid rgb(172, 171, 171)",
  background: "rgba(24, 24, 24,.0)",
};
