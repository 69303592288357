export const POST_CONSULTANT = () =>
  `https://api.modulin.in/api/enquire/create`;
export const POST_CONTACT_US = () =>
  `https://api.modulin.in/api/enquire/contact`;

//   LOGNI
export const POST_SEND_OTP = () => `https://api.modulin.in/api/otp/send`;
export const POST_RESEND_OTP = () => `https://api.modulin.in/api/otp/resend`;
export const POST_VERIFY_OTP = () => `https://api.modulin.in/api/otp/verify`;

// User
export const POST_UPDATE_ROLE = () =>
  `https://api.modulin.in/api/user/updateRole`;
export const POST_USER_DETAIL = () =>
  `https://api.modulin.in/api/user/userdetails`;
export const POST_UPDATE_PROFILE = () =>
  `https://api.modulin.in/api/user/profile`;

// Admin User & Agents
export const POST_USER_AGENTS_ADMIN = () =>
  `https://api.modulin.in/api/admin/users`;
export const POST_UPDATE_AGENTS_STATUS = () =>
  `https://api.modulin.in/api/admin/updatestatus`;

// Enquiry
export const GET_ENQUIRY_AGENT = () =>
  `https://api.modulin.in/api/agent/getusers`;
export const POST_ENQUIRY_ADMIN = () =>
  `https://api.modulin.in/api/admin/enquiries`;

// Consulation
export const GET_CONSULT = () => `https://api.modulin.in/api/admin/consultants`;
